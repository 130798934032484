import { Avatar, Menu, MenuItem, Theme, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UrlPathLogin } from "../../api/url";
import { canMaintainAdminPassword, logout } from "../../store/actions/LoginActions";
import { KNISTR_BLUE } from "../atoms/ImsMaterialTheme";
import ChangePassword from "../user/ChangePassword";
import ShowRoles from "../user/ShowRoles";
import { useTranslation } from "react-i18next";

const styles = (theme: Theme) => ({
  avatar: {
    backgroundColor: KNISTR_BLUE,
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
  },
});

const AvatarMenu = () => {
  const theme = useTheme();
  const classes = styles(theme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [showRolesVisible, setShowRolesVisible] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {changePasswordVisible && <ChangePassword setChangePasswordVisible={setChangePasswordVisible} />}
      {showRolesVisible && <ShowRoles setShowRolesVisible={setShowRolesVisible} />}
      <Avatar data-testid="avatar" sx={classes.avatar} onClick={handleClick}></Avatar>
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MenuItem
          onClick={() => {
            logout();
            navigate(UrlPathLogin);
          }}
        >
          {t('account.logout')}
        </MenuItem>
        {canMaintainAdminPassword() && (
          <MenuItem
            onClick={() => {
              setChangePasswordVisible(true);
              handleClose();
            }}
          >
            {t('account.password.change')}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setShowRolesVisible(true);
            handleClose();
          }}
        >
          {t('account.roles.show')}
        </MenuItem>
      </Menu>
    </>
  );
};
export default AvatarMenu;
